// Guidelines:


// Use @use, not @import - we are using dart sass so we can do that.
////// Edit all theme files simultaneously if adding new properties ///////

// Given this structure, if you are adding a new property, that property must appear in every theme file.
// In other words, every theme file will look completely identical except for the values of the variables.
// If you don't do this, there will be 'empty variables' when switching to themes. Obviously no good.

// Variables are named [object/usage]-[modifier]-[property] while sticking as close to the style guideline as possible
// Examples: [primary-button]-[inverted]-[bg-color], [bg-elevation]-[one], [status]-[critical]-[color]

// DARK

//////// Background colors ////////

$elevation-zero-bg-color: #14181A;
$elevation-one-bg-color: #202426;
$elevation-two-bg-color: #25282A;
$elevation-three-bg-color: #2E3133;
$modal-bg-color: #262A2C;

$partition-line-color: #343B3D;

$table-header-color: #AAADB3;
$table-normal-color: #BABCC2;
$table-even-row-bg-color: #202426;
$table-odd-row-bg-color: #232729;

		// partition lines, row colors, nav colors & hovers here

//////// Buttons ////////

$primary-button-bg-color: #579999;
$primary-button-text-color: #051D1D;
$primary-button-inverted-bg-color: transparent;
$primary-button-inverted-text-color: #579999;
$primary-button-border-color-inverted: #579999;

$primary-button-hover-bg-color: #66B3B3;
$primary-button-hover-text-color: #051D1D;
$primary-button-focused-bg-color: #489999; // pressed / focused
$primary-button-focused-text-color: #051D1D; // pressed / focused

		// secondary, danger, disabled button colors

$secondary-button-bg-color: #849299;

$disabled-button-bg-color: #444444;
$disabled-button-text-color: #777777;
$disabled-button-inverted-bg-color: #444444;
$disabled-button-inverted-text-color: #555555;

//////// Links, Labels, Radio / Checkboxes ////////

		// link text colors
		// label text colors and outlines ( + radio / checkbox)

$label-default-border-color: #6D758F;
$label-default-text-color: #AAADB3;

$label-hover-border-color: #7E849E;
$label-hover-text-color: #AAADB3;

$label-active-fill-color: #8995C9; // also border
$label-active-text-color: #BABCC2;
$label-active-inverted-text-color: #8995C9;

$label-disabled-fill-color: #27282B;
$label-disabled-text-color: #555858;
$label-disabled-border-color: #54575F;

//////// Tabs / Pills ////////

	// active, hover, disabled

$tab-pill-active-bg-color: #8995C9;
$tab-pill-active-text-color: #222729;

$tab-pill-hover-bg-color: #2D2F38;
$tab-pill-hover-text-color: #AFABC9;
$tab-pill-hover-border-color: #7B8096;

$tab-pill-default-border-color: #7B8096;
$tab-pill-default-text-color: #A5ABC9;


//////// Status Colors ////////

$status-neutral-color: #555858; // gray
$status-good-color: #488A5E; // green
$status-warning-color: #998950; // yellow
$status-danger-color: #996146; // orange
$status-critical-color: #994D4D; // red

//////// Dropdowns ////////

	// default, active, filled, disabled for COLLAPSED menu

$dropdown-label-default-text-color: #AAADB3;
$dropdown-placeholder-default-text-color: #73777E;
$dropdown-outline-default-color: #6E767A;

$dropdown-label-active-text-color: #AAADB3;
$dropdown-placeholder-active-text-color: #D3D5DB;
$dropdown-outline-active-color: #999999;

$dropdown-label-filled-text-color: #AAADB3;
$dropdown-placeholder-filled-text-color: #BABCC2;
$dropdown-outline-filled-color: #6E767A;

$dropdown-label-disabled-text-color: #555858;
$dropdown-placeholder-disabled-text-color: #555858;
$dropdown-outline-disabled-color: #54575F;
$dropdown-fill-disabled-color: #27282B; // All others are transparent?

	// OPEN menu colors, default, hover, selected

$dropdown-menu-default-bg-color: #262A2C;
$dropdown-menu-default-text-color: #AAADB3;
$dropdown-menu-default-separator-color: #343B3D;

$dropdown-menu-hover-bg-color: #313238;
$dropdown-menu-hover-text-color: #BABCC2;

$dropdown-menu-selected-bg-color: #8995C9;
$dropdown-menu-selected-text-color: #222729;

//////// Text Field Color ////////

$tab-active-text-color: #A5ABC9;
$title-text-color: #AAADB3; // Also label
$normal-text-color: #A8A8A8;
$placeholder-text-color: #73777E;
$disabled-text-color: #555858;
$highlight-active-text-color: #D3D5DB;

$green-text-color: #488A5E;
$red-text-color: #994D4D;
$link-text-color: #7BA3FF;




//////// MIXINS ////////
//could extract these to an effects file & make client supply colors

@mixin tile-drop-shadow { // elevation 3 or all elevations?
	box-shadow: 0px 0px 6px 0px rgba(#202426, .3); // x, y, blur, spread, color - IE does not support #RRGGBBAA format
}

@mixin modal-drop-shadow {
	box-shadow: 0px 0px 10px 0px rgba(#000000, .4); // x, y, blur, spread, color - IE does not support #RRGGBBAA format
}
