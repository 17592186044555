@use "../themes/light";
@use "../themes/dark";
@use "../themes/effects";
@use "../themes/font";

html {
	&[react-theme="dark-theme"] {
		background-color: dark.$elevation-zero-bg-color;
	}

	&[react-theme="light-theme"] {
		background-color: light.$elevation-zero-bg-color;
	}
}

.App {
	
	min-height: 100vh;

  &.dark-theme {
  	background-color: dark.$elevation-zero-bg-color;
  	color: dark.$normal-text-color;
  }

  &.light-theme {
  	//background-color: light.$elevation-zero-bg-color;
  	background-color: #f6f8fe;
  	color: light.$normal-text-color;
  }

// COMMONS - use rarely! Only if the item cannot be put into its own component!
// If it can be its own component / feature ( e.g. 'createReportButton' ) give it its own space!

  // for copy functionality
  #copy-cell-id {
    position: absolute;
    left: -9999px;
	}
}

.refresh-btn {
  @include effects.primary-btn-colors();
  padding: .25rem .75rem;
  font-size: font.$font-size + 1;
}


$collapsed-width: 64px;

.content-margin--collapsed {
  margin-left: $collapsed-width;
  position: relative; // for children to position 'centered' on viewable area.
}
