// Guidelines:


// Use @use, not @import - we are using dart sass so we can do that.
////// Edit all theme files simultaneously if adding new properties ///////

// Given this structure, if you are adding a new property, that property must appear in every theme file.
// In other words, every theme file will look completely identical except for the values of the variables.
// If you don't do this, there will be 'empty variables' when switching to themes. Obviously no good.

// Variables are named [object/usage]-[modifier]-[property] while sticking as close to the style guideline as possible
// Examples: [primary-button]-[inverted]-[bg-color], [bg-elevation]-[one], [status]-[critical]-[color]

// LIGHT

//////// Background colors ////////


// $elevation-zero-bg-color: invert(#080B0B);
$elevation-zero-bg-color: invert(#000000);
$elevation-one-bg-color: invert(#0F1412);
$elevation-two-bg-color: invert(#14181A);
$elevation-three-bg-color: invert(#202426);
$modal-bg-color: invert(#25282A);

// Old values...

// $elevation-zero-bg-color: invert(#14181A);
// $elevation-one-bg-color: invert(#202426);
// $elevation-two-bg-color: invert(#25282A);
// $elevation-three-bg-color: invert(#2E3133);
// $modal-bg-color: invert(#262A2C);

$partition-line-color: invert(#343B3D);

$table-header-color: invert(#AAADB3);
$table-normal-color: invert(#BABCC2);
$table-even-row-bg-color: invert(#202426);
$table-odd-row-bg-color: invert(#232729);

		// partition lines, row colors, nav colors & hovers here

//////// Buttons ////////

$primary-button-bg-color: invert(#579999);
$primary-button-text-color: invert(#051D1D);
$primary-button-inverted-bg-color: transparent;
$primary-button-inverted-text-color: invert(#579999);
$primary-button-border-color-inverted: invert(#579999);

$primary-button-hover-bg-color: invert(#66B3B3);
$primary-button-hover-text-color: invert(#051D1D);
$primary-button-focused-bg-color: invert(#489999); // pressed / focused
$primary-button-focused-text-color: invert(#051D1D); // pressed / focused

		// secondary, danger, disabled button colors

$secondary-button-bg-color: #849299;

$disabled-button-bg-color: invert(#444444);
$disabled-button-text-color: invert(#777777);
$disabled-button-inverted-bg-color: #444444;
$disabled-button-inverted-text-color: #555555;

//////// Links, Labels, Radio / Checkboxes ////////

		// link text colors
		// label text colors and outlines ( + radio / checkbox)

$label-default-border-color: invert(#6D758F);
$label-default-text-color: invert(#AAADB3);

$label-hover-border-color: invert(#7E849E);
$label-hover-text-color: invert(#AAADB3);

$label-active-fill-color: invert(#8995C9); // also border
$label-active-text-color: invert(#BABCC2);
$label-active-inverted-text-color: invert(#8995C9);

$label-disabled-fill-color: invert(#27282B);
$label-disabled-text-color: invert(#555858);
$label-disabled-border-color: invert(#54575F);

//////// Tabs / Pills ////////

	// active, hover, disabled

$tab-pill-active-bg-color: invert(#8995C9);
$tab-pill-active-text-color: invert(#222729);

$tab-pill-hover-bg-color: invert(#2D2F38);
$tab-pill-hover-text-color: invert(#AFABC9);
$tab-pill-hover-border-color: invert(#7B8096);

$tab-pill-default-border-color: invert(#7B8096);
$tab-pill-default-text-color: invert(#A5ABC9);


//////// Status Colors ////////

$status-neutral-color: #555858; // gray
$status-good-color: #488A5E; // green
$status-warning-color: #998950; // yellow
$status-danger-color: #996146; // orange
$status-critical-color: #994D4D; // red

//////// Dropdowns ////////

	// default, active, filled, disabled for collapsed menu

$dropdown-label-default-text-color: invert(#AAADB3);
$dropdown-placeholder-default-text-color: invert(#73777E);
$dropdown-outline-default-color: invert(#6E767A);

$dropdown-label-active-text-color: invert(#AAADB3);
$dropdown-placeholder-active-text-color: invert(#D3D5DB);
$dropdown-outline-active-color: invert(#999999);

$dropdown-label-filled-text-color: invert(#AAADB3);
$dropdown-placeholder-filled-text-color: invert(#BABCC2);
$dropdown-outline-filled-color: invert(#6E767A);

$dropdown-label-disabled-text-color: invert(#555858);
$dropdown-placeholder-disabled-text-color: invert(#555858);
$dropdown-outline-disabled-color: invert(#54575F);
$dropdown-fill-disabled-color: invert(#27282B); // All others are transparent?

	// open menu colors, default, hover, selected

$dropdown-menu-default-bg-color: invert(#262A2C);
$dropdown-menu-default-text-color: invert(#AAADB3);
$dropdown-menu-default-separator-color: invert(#343B3D);

$dropdown-menu-hover-bg-color: invert(#313238);
$dropdown-menu-hover-text-color: invert(#BABCC2);

$dropdown-menu-selected-bg-color: invert(#8995C9);
$dropdown-menu-selected-text-color: invert(#222729);

//////// Text Field Color ////////

$tab-active-text-color: invert(#A5ABC9);
$title-text-color: invert(#AAADB3); // Also label
$normal-text-color: invert(#A8A8A8);
$placeholder-text-color: invert(#73777E);
$disabled-text-color: invert(#555858);
$highlight-active-text-color: invert(#D3D5DB);

$green-text-color: #488A5E;
$red-text-color: #994D4D;
$link-text-color: #7BA3FF;




//////// MIXINS ////////
//could extract these to an effects file & make client supply colors

@mixin tile-drop-shadow { // elevation 3 or all elevations?
	box-shadow: 0px 0px 6px 0px rgba(#202426, .3); // x, y, blur, spread, color - IE does not support #RRGGBBAA format
}

@mixin modal-drop-shadow {
	//box-shadow: 0px 0px 10px 0px rgba(#000000, .4); // x, y, blur, spread, color - IE does not support #RRGGBBAA format
	box-shadow: inset -2px 0px 4px 0px rgba(#000000, .4); // x, y, blur, spread, color - IE does not support #RRGGBBAA format
}
