@use "../../themes/light";
@use "../../themes/dark";

$collapsed-width: 64px;
$expanded-width: 160px;

// Margin for rest of page
.side-navbar__margin--expanded {
  margin-left: $expanded-width;
  position: relative; // for children to position 'centered' on viewable area.
}

.side-navbar__margin--collapsed {
  margin-left: $collapsed-width;
  position: relative; // for children to position 'centered' on viewable area.
}

// Side bar.
.side-navbar {

  transition: min-width .5s ease, width .5s ease, max-width .5s ease;

  &.side-navbar--expanded {
    min-width: $expanded-width;
    max-width: $expanded-width;
    overflow: scroll;
    div[role="presentation"] div[class*="sidenav---navitem"]:after {
      width: calc(100% + 32px); //32px based on right padding from sidenavbar.js styling.
    }
  }

  &.side-navbar--collapsed {
    min-width: $collapsed-width;
    max-width: $collapsed-width;
  }

  .dark-theme & {
    background-color: dark.$elevation-one-bg-color;
    color: dark.$normal-text-color;
    @include dark.modal-drop-shadow;
  }

  .light-theme & {
    //background-color: light.$elevation-one-bg-color;
    background-color: #edf3f9;
    color: light.$normal-text-color;
    @include light.modal-drop-shadow;
  }

  div[role="presentation"]:hover {
    //target this for hover colors
  }

  .dark-theme &#{&}#{&}#{&}#{&} div[class*="sidenav---selected"] div[class*="sidenav---navicon"] .side-navbar__icon {
    color: dark.$tab-active-text-color;  
  }
  .light-theme &#{&}#{&}#{&}#{&} div[class*="sidenav---selected"] div[class*="sidenav---navicon"] .side-navbar__icon {
    //color: light.$tab-active-text-color;  
    color: #56707e;  
  }
  .light-theme &#{&}#{&}#{&}#{&} div[class*="sidenav---selected"] {
    //color: light.$tab-active-text-color;  
    color: #56707e;  
    background-color: #f6f8fe;
  }


}

.sidenavbar_toggle {
  position: relative;
  float: left;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;

  .dark-theme & {
    color: dark.$primary-button-bg-color;
  }

  .light-theme & {
    color: light.$primary-button-bg-color;
  }
}
.sidenavbar_toggle:focus {
  outline: none;
}

.sidenavbar_logo {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  font-size: 20px;
  margin-left: 5px;

  .dark-theme & {
    color: dark.$normal-text-color;
  }

  .light-theme & {
    color: light.$normal-text-color;
  }

  &_icon, &_full {
    display: block;
    float: left;
    height: 33px;
    background-color: transparent;
    //opacity: 0.7;
    // line-height: 50px;
    text-align: center;
    margin-top: 10px;
    margin-left: 11px;
  }
}

.sidenavbar_logo_full {
  .dark-theme & {
    color: dark.$normal-text-color;
    > path:nth-child(2) {
      fill: #fff;
    }
  }
}

.side-navbar__icon {
  .dark-theme.dark-theme.dark-theme.dark-theme & {
    color: dark.$normal-text-color;

    @mixin transform($property) {
      -webkit-transform: $property;
      -ms-transform: $property;
      transform: $property;
    }

    &.side-navbar__moon {
      @include transform(rotate(-37deg));
    } 

    & path {
      stroke: dark.$normal-text-color;
    }
  }

  .light-theme.light-theme.light-theme.light-theme & {
    color: light.$normal-text-color;

    & path {
      stroke: light.$normal-text-color;
    }
  }
}

.side-navbar__text {
  .dark-theme.dark-theme.dark-theme.dark-theme & {
    color: dark.$normal-text-color;
  }

  .light-theme.light-theme.light-theme.light-theme & {
    color: light.$normal-text-color;
  }
}

.side-navbar__footer {
  &#{&} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}

.side-navbar__theme-switcher {
  &#{&} {
    position: absolute;
    bottom: 50px;
    width: 100%;
  }
}

