// Useful effects & shorthand.
@use "dark";
@use "light";
@use "font";
@use "sass:color";

/* DIRECTORY OF EFFECTS

rigid-height - forces a specific max/min/standard height
rigid-width - same as above

clickable - applies styling to represent a table item as clickable
modal-basics - applies basic styling for a modal w/ modal.header and modal.body
pseudo-states-reset-box-shadow - removes box shadow for psuedo selectors
pseudo-states-transparent-bg-color - sets bg transparent for psuedo selectors
pseudo-states-no-outline - sets outline:none for psuedo selectors
transparent-input-box - basic styling for an input tag

radio-btn-colors - creates radio button for input[type=radio] + label
checkbox-btn-colors
checkbox-btn-colors-two
disabled-checkbox-colors

// May need to be moved to different file:
primary-btn-colors - colors and hover / click states for a 'primary btn'
disabled-btn-colors

primary-dropdown-menu-styles - basic styling for the dropdown-menu part of a dropdown
*/

// Force an element to be a specific height ( including min and max )
@mixin rigid-height($height) {
	height: $height;
	min-height: $height;
	max-height: $height;
}

// Force an element to be a specific width ( including min and max )
@mixin rigid-width($width) {
	width: $width;
	min-width: $width;
	max-width: $width;
}

@mixin clickable() {
	cursor: pointer;
	width: auto;
	.dark-theme & {
		border-bottom: 1px solid dark.$primary-button-bg-color;
	}
	.light-theme & {
		border-bottom: 1px solid light.$primary-button-bg-color;
	}
}

// slap onto whatever modal at the 'dialog' level - make sure it's in a namespaced class.
// repeating the same stuff gets old fast, so.
/* Contains:
	 - Drop shadow for outer box
	 - Text and bg color for modal content
	 - Bottom border for header
	 - Positioning for modal close button in header
	 - border radius for 'card' if used
*/
@mixin modal-basics() {

	.modal-content {
		.dark-theme & {
			@include dark.modal-drop-shadow();
			background-color: dark.$elevation-one-bg-color;
			color: dark.$normal-text-color;
		}
		.light-theme & {
			@include light.modal-drop-shadow();
			background-color: light.$elevation-one-bg-color;
			color: light.$normal-text-color;
		}
	}

	.modal-header {
		.dark-theme & {
      border-bottom: 1px solid dark.$partition-line-color;
      background-color: dark.$elevation-one-bg-color;
    }
    .light-theme & {
      border-bottom: 1px solid light.$partition-line-color;
      background-color: light.$elevation-one-bg-color;
    }
	}

	.modal-header .close {
		position: absolute;
		right: 1rem;
		top: 1rem;
		text-shadow: unset;

		.dark-theme & {
      color: dark.$title-text-color;
    }

    .light-theme & {
      color: light.$title-text-color;
    }
	}

	.modal-footer {
		.dark-theme & {
      border-top: 1px solid dark.$partition-line-color;
    }
    .light-theme & {
      border-top: 1px solid light.$partition-line-color;
    }
	}

	.card-body {
		border-radius: 3px;
	}
}

// Good for buttons & bootstrap which have a lot of little psuedo states that can cause mischief
@mixin pseudo-states-reset-box-shadow() {
	&:focus, &:active, &:hover, &:visited, &:focus-within, &:target {
    box-shadow: none;
  }
}

@mixin pseudo-states-transparent-bg-color() {
	&:focus, &:active, &:hover, &:visited, &:focus-within, &:target {
    background-color: transparent;
  }
}

@mixin pseudo-states-no-outline() {
	&:focus, &:active, &:hover, &:visited, &:focus-within, &:target {
    outline: none;
  }
}

// Sets up an input box for transparency.
// Includes box hover states, text colors.
@mixin transparent-input-box($font-size: font.$font-size-small) {
  border-radius: 3px;
  background-color: transparent;
  box-shadow: unset;

  .dark-theme & {
    color: dark.$dropdown-placeholder-default-text-color;
    border: 1px solid dark.$dropdown-outline-default-color;
  }

  .light-theme & {
    color: light.$dropdown-placeholder-default-text-color;
    border: 1px solid light.$dropdown-outline-default-color;
  }

  &:hover, &:focus {
  	.dark-theme & {
	    color: dark.$dropdown-placeholder-active-text-color;
	    border: 1px solid dark.$dropdown-outline-active-color;
	  }

	  .light-theme & {
	    color: light.$dropdown-placeholder-active-text-color;
	    border: 1px solid light.$dropdown-outline-active-color;
	  }
  }

  &:disabled {
  	.dark-theme & {
  		color: dark.$dropdown-placeholder-disabled-text-color;
  		border: 1px solid dark.$dropdown-outline-disabled-color;
  		background-color: dark.$dropdown-fill-disabled-color;
  	}
  	.light-theme & {
  		color: light.$dropdown-placeholder-disabled-text-color;
  		border: 1px solid light.$dropdown-outline-disabled-color;
  		background-color: light.$dropdown-fill-disabled-color;
  	}
  }

  // NOT SUPPORTED ON EVERY BROWSER.
  // works if it's on an input elem
  // pseudo elems follow strange rules, not fully supported, not all props can be used
  // further, some browsers include an !important, so working around this is tough.
  // read below.
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
  	// force a 1+ hour transition from 'transparent' bg to achieve transparency
  	transition: background-color 5000s ease-in-out 0s;
  	font-size: $font-size;
  	
  	// box shadow inset for an actual bg color
  	// -webkit-box-shadow: 0 0 0px 1000px #000 inset;

  	.dark-theme & {
  		// 'color' prop
  		-webkit-text-fill-color: dark.$dropdown-placeholder-active-text-color;
  	}
  	.light-theme & {
  		// 'color' prop
  		-webkit-text-fill-color: light.$dropdown-placeholder-active-text-color;
  	}
  }
}

// if you don't like the default colors, override it after @include'ing this mixin ( for example, box shadow color! )
// works for only this HTML setup: an <input type="radio"> followed immediately by a <label>
// this mixin should target the input and is built for the <Form.Check> utility
// no disabled state included yet
@mixin radio-btn-colors($font-size: font.$font-size-small) {
	// hide the input radio
	& {
		display: none;
		width: 0px;
		height: 0px;
		margin: 0px;
		padding: 0px;
	}

	// baseline radio circle creation
	& + label:before {
		content: '';
		margin-right: 5px;

		@include rigid-height($font-size);
		@include rigid-width($font-size);

		border-radius: 50%;
		display: inline-block;

		background-color: transparent;

		.dark-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
	}

	// circle color on hover
	& + label:hover:before {
		.dark-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}	
	}

	// checked circle
	&:checked + label:before {
		.dark-theme & {
			background-color: dark.$label-active-fill-color;
			border-color: dark.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
		}
		.light-theme & {
			background-color: light.$label-active-fill-color;
			border-color: light.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
		}
	}



}


// works for only this HTML setup: an <input type="checkbox"> followed immediately by a <label>
// this mixin should target the input and is built for the <Form.Check> utility
// no disabled state included yet
@mixin checkbox-btn-colors($font-size: font.$font-size-small) {
	// hide the input radio
	& {
		display: none;
		width: 0px;
		height: 0px;
		margin: 0px;
		padding: 0px;
	}

	// baseline radio circle creation
	& + label:before {
		content: '';
		margin-right: 5px;

		@include rigid-height($font-size);
		@include rigid-width($font-size);

		// border-radius: 50%;
		display: inline-block;

		background-color: transparent;

		.dark-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
	}

	// circle color on hover
	& + label:hover:before {
		.dark-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}	
	}

	// checked circle
	&:checked + label:before {
		.dark-theme & {
			background-color: dark.$label-active-fill-color;
			border-color: dark.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
		}
		.light-theme & {
			background-color: light.$label-active-fill-color;
			border-color: light.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
		}
	}

	@include disabled-checkbox-colors($font-size);

}


// WIP - TODO
@mixin checkbox-btn-colors-two($font-size: font.$font-size-small) {
	// hide the input radio
	& {
		display: none;
		width: 0px;
		height: 0px;
		margin: 0px;
		padding: 0px;
	}

	// baseline radio circle creation
	& + label:before {
		content: '';
		margin-right: 5px;

		@include rigid-height($font-size);
		@include rigid-width($font-size);

		// border-radius: 50%;
		display: inline-block;

		background-color: transparent;

		.dark-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-default-border-color;
		}
	}

	// circle color on hover
	& + label:hover:before {
		.dark-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}
		.light-theme & {
			border: 1px solid dark.$label-hover-border-color;
		}	
	}

	// checked circle
	&:checked + label:before {
		.dark-theme & {
			background-color: dark.$label-active-fill-color;
			border-color: dark.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 dark.$elevation-one-bg-color inset;
		}
		.light-theme & {
			background-color: light.$label-active-fill-color;
			border-color: light.$label-active-fill-color;
			box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-webkit-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
			-moz-box-shadow: 0 0 0 $font-size/5 light.$elevation-one-bg-color inset;
		}
	}

	@include disabled-checkbox-colors($font-size);

}


// Meant to target an 'input' that is followed by a label ( as by the Form.Check utility )
// and in conjunction with the checkbox-btn-colors mixin
@mixin disabled-checkbox-colors($font-size: font.$font-size-small) {

	&:disabled + label:before {
		box-shadow: unset;
		-webkit-box-shadow: unset;
		-moz-box-shadow: unset;

		.dark-theme & {
			background-color: dark.$label-disabled-fill-color;
			border-color: dark.$label-disabled-border-color;
		}
		.light-theme & {
			background-color: light.$label-disabled-fill-color;
			border-color: light.$label-disabled-border-color;
		}
	}

	&:disabled + label {
		.dark-theme & {
			color: dark.$label-disabled-text-color;
		}
		.light-theme & {
			color: light.$label-disabled-text-color;
		}
	}



}

@mixin primary-btn-colors-inverted() {
	@include pseudo-states-reset-box-shadow();
	.dark-theme.dark-theme & {
    background-color: dark.$primary-button-inverted-bg-color;
    border-color: dark.$primary-button-bg-color;
    color: dark.$primary-button-inverted-text-color;

    &:hover {
      background-color: dark.$primary-button-hover-bg-color;
      border-color: dark.$primary-button-hover-bg-color;
      color: dark.$primary-button-hover-text-color;
    }
    /*&:focus, &:active {
      background-color: dark.$primary-button-focused-bg-color;
      border-color: dark.$primary-button-focused-bg-color;
      color: dark.$primary-button-focused-text-color;
    }*/
  }
  .light-theme.light-theme & {
    background-color: light.$primary-button-inverted-bg-color;
    border-color: light.$primary-button-bg-color;
    color: light.$primary-button-inverted-text-color;

    &:hover {
      background-color: light.$primary-button-hover-bg-color;
      border-color: light.$primary-button-hover-bg-color;
      color: light.$primary-button-hover-text-color;
    }
    /*&:focus, &:active {
      background-color: light.$primary-button-focused-bg-color;
      border-color: light.$primary-button-focused-bg-color;
      color: light.$primary-button-focused-text-color;
    }*/
  }

  // @include disabled-btn-colors(); // should be inverted disabled
}

@mixin primary-btn-colors() {
	@include pseudo-states-reset-box-shadow();
	.dark-theme.dark-theme & {
    background-color: dark.$primary-button-bg-color;
    border-color: dark.$primary-button-bg-color;
    color: dark.$primary-button-text-color;

    &:hover {
      background-color: dark.$primary-button-hover-bg-color;
      border-color: dark.$primary-button-hover-bg-color;
      color: dark.$primary-button-hover-text-color;
    }
    &:focus, &:active {
      background-color: dark.$primary-button-focused-bg-color;
      border-color: dark.$primary-button-focused-bg-color;
      color: dark.$primary-button-focused-text-color;
    }
  }
  .light-theme.light-theme & {
    //background-color: light.$primary-button-bg-color;
    //border-color: light.$primary-button-bg-color;
    //color: light.$primary-button-text-color;

    //background-color: #ffffff;
    //border: 1px solid #dde4e7;
    //border-radius: 2px;
    //color: #37474e;

    background-color: #095fb3;
    color: #ffffff;
    border: 1px solid #095fb3;
    border-radius: 2px;

    //&:hover {
    //  //background-color: light.$primary-button-hover-bg-color;
    //  //border-color: light.$primary-button-hover-bg-color;
    //  //color: light.$primary-button-hover-text-color;
    //  background-color: #dfe5e8;
    //  border-color: #dfe5e8;
    //  border-radius: 2px;
    //  color: #193daa;
    //}

    //&:focus, &:active {
    //  background-color: #136180;
    //  border-color: #136180;
    //  color: light.$primary-button-focused-text-color;
    //}
  }

  @include disabled-btn-colors();
}


// special case for blacklist where we don't want hovers / etc.
@mixin blacklist-primary-btn-colors () {
	@include primary-btn-colors();

	// success
	.dark-theme.dark-theme &.success {
    background-color: dark.$green-text-color;
    border-color: dark.$primary-button-bg-color;
    color: dark.$primary-button-text-color;

    &:hover {
      background-color: dark.$green-text-color;
      border-color: dark.$primary-button-bg-color;
      color: dark.$primary-button-text-color;
    }
    &:focus, &:active {
      background-color: dark.$green-text-color;
      border-color: dark.$primary-button-bg-color;
      color: dark.$primary-button-text-color;
    }
  }
  .light-theme.light-theme &.success {
    background-color: light.$green-text-color;
    border-color: light.$primary-button-bg-color;
    color: light.$primary-button-text-color;

    &:hover {
      background-color: light.$green-text-color;
      border-color: light.$primary-button-bg-color;
      color: light.$primary-button-text-color;
    }
    &:focus, &:active {
      background-color: light.$green-text-color;
      border-color: light.$primary-button-bg-color;
      color: light.$primary-button-text-color;
    }
  }


  // failure
  .dark-theme.dark-theme &.failure {
    background-color: dark.$status-critical-color;
    border-color: dark.$primary-button-bg-color;
    color: dark.$primary-button-text-color;

    &:hover {
      background-color: dark.$status-critical-color;
      border-color: dark.$primary-button-bg-color;
      color: dark.$primary-button-text-color;
    }
    &:focus, &:active {
      background-color: dark.$status-critical-color;
      border-color: dark.$primary-button-bg-color;
      color: dark.$primary-button-text-color;
    }
  }
  .light-theme.light-theme &.failure {
    background-color: light.$status-critical-color;
    border-color: light.$primary-button-bg-color;
    color: light.$primary-button-text-color;

    &:hover {
      background-color: light.$status-critical-color;
      border-color: light.$primary-button-bg-color;
      color: light.$primary-button-text-color;
    }
    &:focus, &:active {
      background-color: light.$status-critical-color;
      border-color: light.$primary-button-bg-color;
      color: light.$primary-button-text-color;
    }
  }


}

@mixin disabled-btn-colors() {
	@include pseudo-states-reset-box-shadow();
	.dark-theme.dark-theme &:disabled {
		background-color: dark.$disabled-button-bg-color;
		border-color: dark.$disabled-button-bg-color;
		color: dark.$disabled-button-text-color;
	}
	.light-theme.light-theme &:disabled {
		background-color: light.$disabled-button-bg-color;
		border-color: light.$disabled-button-bg-color;
		color: light.$disabled-button-text-color;
	}
}

@mixin scrollbar-style() {
	// firefox scrollbars.
  scrollbar-width: thin;

  // edge / chrome / wekit scrollbars
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .dark-theme &::-webkit-scrollbar {
    background: dark.$elevation-zero-bg-color;
  }

  .dark-theme &::-webkit-scrollbar-thumb {
    background: color.scale(dark.$elevation-zero-bg-color, $lightness: 30%);
  }

  .light-theme &::-webkit-scrollbar {
    background: light.$elevation-zero-bg-color;
  }

  .light-theme &::-webkit-scrollbar-thumb {
    background: color.scale(light.$elevation-zero-bg-color, $lightness: -30%);
  }


}

@mixin primary-dropdown-menu-styles() {
	font-size: font.$font-size-small;
  width: auto;

  .dark-theme & {
    @include dark.modal-drop-shadow();
    background-color: dark.$dropdown-menu-default-bg-color;
  }
  .light-theme & {
    @include light.modal-drop-shadow();
    background-color: light.$dropdown-menu-default-bg-color;
  }


  & .dropdown-item {
    padding: 3px 8px;
    white-space: nowrap;
    width: 100%;
    position: relative;
    .dark-theme & {
      color: dark.$primary-button-inverted-text-color;
    }
    .light-theme & {
      color: light.$primary-button-inverted-text-color;
    }

    &:not(:last-child) {
      .dark-theme & {
        border-bottom: 1px solid dark.$partition-line-color;
      }
      .light-theme & {
        border-bottom: 1px solid light.$partition-line-color;
      }
    }

    &:hover {
      @include font.widthless-bold();
      .dark-theme & {
        background-color: dark.$dropdown-menu-hover-bg-color;
        
      }
      .light-theme & {
        background-color: light.$dropdown-menu-hover-bg-color;
      }
    }
  }

}
